import {
  gql,
  DocumentNode,
  TypedDocumentNode,

} from "@apollo/client";
import { SetupData, EmployeeInput, BulkCaseCreateInput, ApplicationResponse, ApplicationPlanDataInput, SendCompletedApplicationEmailResponse } from "services/Interfaces";

export enum BenefitTier {
  individual = "individual",
  family = "family",
  none = "none",
}

interface _Mutations {
  EMAIL_QUOTE: DocumentNode,
  setUserClaims: DocumentNode,
  contactUs: DocumentNode
  setPublicToken: TypedDocumentNode<{ hris: { merge: { setPublicToken: boolean } } }, { publicToken: string, companyId: string }>,
  loadOpportunity: TypedDocumentNode<{ loadOpportunity: boolean }, { opportunityId: string }>,
  syncHris: TypedDocumentNode<{ syncHris: boolean }, { companyId: string }>,
  connectUserToCompany: TypedDocumentNode<{ connectUserToCompany: boolean }, { companyId: string, userId: string, inviteId: string }>,
  resendConnectEmail: TypedDocumentNode<{ resendConnectEmail: boolean }, { companyId: string, inviteId: string }>,
  setupCompany: TypedDocumentNode<{ setupCompany: boolean }, SetupData>,
  setUserDirectDeposit: TypedDocumentNode<{ setUserDirectDeposit: boolean }, { accountNumber: string, nameOnAccount: string, routingNumber: string }>
  createCase: TypedDocumentNode<{ createCase: string }, {
    companyId: string,
    employeeId: string,
    checklistId: string,
    terminationDate: string,
    lastDay: string,
    reason: string,
    terminationType: string,
    benefitTier: BenefitTier,
    secure: boolean
  }>
  uploadBatch: TypedDocumentNode<{ uploadBatch: string }, {
    companyId: string,
    employees: EmployeeInput[],
  }>
  bulkCaseCreate: TypedDocumentNode<{ bulkCaseCreate: boolean }, {
    cases: BulkCaseCreateInput[],
  }>
  syncDataForExitCode: TypedDocumentNode<{ syncDataForExitCode: boolean }, { companyId: string, exitCode: string, birthDate: string, lastName: string, zipCode: string, isAdmin?: boolean, userUid?: string }>
  submitReimbursementRequest: TypedDocumentNode<{ submitReimbursementRequest: boolean }, {
    userId: string,
    whenBenefitId: string,
    files: { name: string, path: string, type: string }[],
    amount: number,
    coverageStartDate: string,
    coverageEndDate: string,
  }>
  sendMessage: TypedDocumentNode<{ sendMessage: boolean }, { message: string }>
  cancelMessage: TypedDocumentNode<{ cancelMessage: boolean }>
  clearMessages: TypedDocumentNode<{ clearMessages: boolean }>
  cancelReimbursementRequest: TypedDocumentNode<{ cancelReimbursementRequest: boolean }, { userId: string, id: string, whenBenefitId: string }>
  releaseCaseBenefit: TypedDocumentNode<{ releaseCaseBenefit: boolean }, {
    companyId: string,
    caseId: string
  }>
  deleteUserDirectDeposit: TypedDocumentNode<{ deleteUserDirectDeposit: boolean }, { userId: string }>
  userSignOut: TypedDocumentNode<{ userSignOut: boolean }, { userId: string }>
  approveReimbursementRequest: TypedDocumentNode<{ approveReimbursementRequest: boolean },
    { requestId: string, requestUserId: string, requestBenefitId: string, approvedAmount: string, notes: string, status: string }>
  denyReimbursementRequest: TypedDocumentNode<{ denyReimbursementRequest: boolean },
    { requestUserId: string, requestId: string, requestBenefitId: string, denialReason: string, notes: string }>,
  additionalInfoRequest: TypedDocumentNode<{ additionalInfoRequest: boolean },
    { requestUserId: string, requestId: string, requestBenefitId: string, msg?: string, status: string }>,
  setHousehold: TypedDocumentNode<{ setHousehold: boolean }, {
    uid: string, members: {
      name: string,
      dob: string,
      tobacco: boolean,
      pregnant: boolean,
      gender: string,
      relationship: string
    }[]
  }>,
  setInsuranceDetails: TypedDocumentNode<{ setInsuranceDetails: boolean }, {
    uid: string, details: {
      county: {
        name: string,
        state: string,
        zipcode: string,
        fips: string
      },
      income: number,
      type: string,
      zipcode: string,
      effectiveDate: string,
      taxFilingStatus: String,
      isNativeAmarican: boolean
    }
  }>,
  setPreferences: TypedDocumentNode<{ setPreferences: boolean }, {
    uid: string, preferences: {
      hmo?: number,
      ppo?: number,
      shortterm?: number,
      lowDeductible?: number,
      lowPremium?: number,
      comprehensiveCoverage?: number,
      networkSize?: number,
      mentalHealth?: number,
      dental?: number,
      vision?: number
    }
  }>,
  createApplication: TypedDocumentNode<{ createApplication: ApplicationResponse }, { planId: string; userId: string }>;
  adminCreateApplication: TypedDocumentNode<
    { adminCreateApplication: ApplicationResponse },
    {
      applicantUserId: string,
      applicationPlanData: ApplicationPlanDataInput
    }
  >,
  submitApplication: TypedDocumentNode<{ submitApplication: Boolean }, { uid: string; applicationId: string }>;
  beginJamieChat: TypedDocumentNode<{ beginJamieChat: boolean }, { userId: string }>;
  saveApplicationSSN: TypedDocumentNode<{ saveApplicationSSN: Boolean }, { uid: string, applicationId: string, ssn: string, householdMemberId: string }>
  addApplicationNote: TypedDocumentNode<{ addApplicationNote: Boolean }, {
    applicationId: string,
    applicationUserId: string,
    note: {
      id: string,
      userName: string,
      date: string,
      note: string
    }
  }>
  sendCompletedApplicationEmail: TypedDocumentNode<{ sendCompletedApplicationEmail: SendCompletedApplicationEmailResponse }, { applicantUserId: string }>
  queueHrisSync: TypedDocumentNode<{ queueHrisSync: boolean }, { companyId: string }>
  connectFinch: TypedDocumentNode<{ hris: { finch: { connectFinch: boolean } } }, { clientCode: string, companyId: string }>
  adminCreateUserFromCase: TypedDocumentNode<{ adminCreateUserFromCase: { uid: string, email: string, admin: boolean } }, { companyId: string, caseId: string, firstName: string, lastName: string, email: string, dateOfBirth: string, zipCode: string }>
  triggerPipeline: TypedDocumentNode<{ triggerPipeline: boolean }, { type: string, parameters?: string }>
}
function getMutations(): _Mutations {
  let Mutations: any = {};
  Mutations.triggerPipeline = gql`
  mutation triggerPipeline($type: String!, $parameters: String) {
    triggerPipeline(type: $type, parameters: $parameters)
  }`;
  Mutations.sendMessage = gql`
  mutation sendMessage($message: String!) {
    sendMessage(message: $message)
  }`;
  Mutations.cancelMessage = gql`
  mutation cancelMessage {
    cancelMessage
  }`;
  Mutations.clearMessages = gql`
  mutation clearMessages {
    clearMessages
  }`;
  Mutations.setHousehold = gql`
  mutation setHousehold($uid:String, $members:[HouseholdMemberInput]) {
    setHousehold(uid: $uid, members: $members)
  }`;
  Mutations.setInsuranceDetails = gql`
  mutation setInsuranceDetails($uid:String, $details:InsuranceDetailsInput) {
    setInsuranceDetails(uid: $uid, details: $details)
  }`;
  Mutations.beginJamieChat = gql`
  mutation beginJamieChat($userId: String!) {
    beginJamieChat(userId: $userId)
  }`;
  Mutations.createApplication = gql`
  mutation createApplication($planId: ID!, $userId: ID!) {
    createApplication(planId: $planId, userId: $userId) {
      applicationId
      success
      message
    }
  }
`;
  Mutations.adminCreateApplication = gql`
   mutation adminCreateApplication(
     $applicantUserId: String!
     $applicationPlanData: ApplicationPlanDataInput!
   ) {
     adminCreateApplication(
       applicantUserId: $applicantUserId
       applicationPlanData: $applicationPlanData
     ) {
       applicationId
       success
       message
     }
   }
 `;
  Mutations.submitApplication = gql`
mutation submitApplication($uid:String, $applicationId:String) {
  submitApplication(uid: $uid, applicationId:$applicationId)
}`;
  Mutations.saveApplicationSSN = gql`
  mutation saveApplicationSSN($uid:String!, $applicationId:ID!, $ssn:String!, $householdMemberId:String!) {
    saveApplicationSSN(uid: $uid, applicationId:$applicationId, ssn:$ssn, householdMemberId:$householdMemberId)
  }`;
  Mutations.addApplicationNote = gql`
  mutation addApplicationNote($applicationId:ID!, $applicationUserId: String!, $note:ApplicationNoteInput!) {
    addApplicationNote(applicationId:$applicationId, applicationUserId:$applicationUserId, note:$note)
  }`;
  Mutations.sendCompletedApplicationEmail = gql`
  mutation sendCompletedApplicationEmail($applicantUserId: String!) {
    sendCompletedApplicationEmail(applicantUserId: $applicantUserId) {
      success
      message
      error
    }
  }`;
  Mutations.setPreferences = gql`
  mutation setPreferences($uid:String, $preferences:PreferencesInput) {
    setPreferences(uid: $uid, preferences: $preferences)
  }`;
  Mutations.submitReimbursementRequest = gql`
  mutation submitReimbursementRequest($userId: String!, $whenBenefitId: String!, $files: [ReimbursementFile!]!, $amount: Float!, $coverageStartDate: String!, $coverageEndDate: String!) {
    submitReimbursementRequest(userId: $userId, whenBenefitId: $whenBenefitId, files: $files, amount: $amount,coverageStartDate: $coverageStartDate, coverageEndDate: $coverageEndDate)
  }`;
  Mutations.cancelReimbursementRequest = gql`
  mutation cancelReimbursementRequest($userId: String!, $id: String!, $whenBenefitId: String!) {
    cancelReimbursementRequest(userId: $userId, id: $id, whenBenefitId: $whenBenefitId)
  }`;
  Mutations.approveReimbursementRequest = gql`
   mutation approveReimbursementRequest($requestId: String!, $requestUserId: String!, $requestBenefitId: String!, $approvedAmount: String!, $notes:String!, $status:String!){
    approveReimbursementRequest(requestId: $requestId, requestUserId: $requestUserId, requestBenefitId: $requestBenefitId, approvedAmount: $approvedAmount, notes: $notes, status: $status)
   } `;
  Mutations.denyReimbursementRequest = gql`
   mutation denyReimbursementRequest($requestUserId: String! ,$requestId: String!, $requestBenefitId: String!, $denialReason: String!, $notes:String){
    denyReimbursementRequest(requestId: $requestId, requestUserId: $requestUserId, requestBenefitId: $requestBenefitId, denialReason: $denialReason, notes: $notes)
   } `;
  Mutations.additionalInfoRequest = gql`
   mutation additionalInfoRequest($requestUserId: String! ,$requestId: String!, $requestBenefitId: String!, $msg: String, $status:String!){
    additionalInfoRequest(requestId: $requestId, requestUserId: $requestUserId, requestBenefitId: $requestBenefitId, msg: $msg, status: $status)
   } `;
  Mutations.syncDataForExitCode = gql`
  mutation syncDataForExitCode($companyId: String!, $exitCode: String!, $birthDate: String!, $lastName: String!, $zipCode: String!, $isAdmin: Boolean, $userUid: String) {
    syncDataForExitCode(companyId: $companyId, exitCode: $exitCode, birthDate: $birthDate, lastName: $lastName, zipCode: $zipCode, isAdmin: $isAdmin, userUid: $userUid)
  } `;
  Mutations.contactUs = gql`mutation ContactUs($name: String, $email: String, $phone: String, $subject: String, $description: String) {
    contactUs(name: $name, email: $email, phone: $phone, subject: $subject, description: $description)
  } `
  Mutations.releaseCaseBenefit = gql`mutation releaseCaseBenefit($companyId: String!, $caseId: String! ){
    releaseCaseBenefit(companyId: $companyId,  caseId: $caseId )
  } `
  Mutations.deleteUserDirectDeposit = gql`mutation deleteUserDirectDeposit($userId: String!){
    deleteUserDirectDeposit(userId: $userId)
  } `
  Mutations.userSignOut = gql`mutation userSignOut($userId: String!){
    userSignOut(userId: $userId)
  } `
  Mutations.createCase = gql`
  mutation createCase(
    $companyId: String!
    $employeeId: String!
    $checklistId: String!
    $terminationDate: String!
    $lastDay: String
    $reason: String!
    $terminationType: String!  
    $benefitTier: BenefitTier!
    $secure: Boolean!
  ) {
    createCase(
      companyId: $companyId
      employeeId: $employeeId
      checklistId: $checklistId
      terminationDate: $terminationDate
      lastDay: $lastDay
      reason: $reason
      terminationType: $terminationType 
      benefitTier: $benefitTier
      secure: $secure
    )
  } `;
  Mutations.uploadBatch = gql`
  mutation uploadBatch($companyId: String!, $employees: [EmployeeInput]) {
    hris{
    csvUpload {
      uploadBatch(companyId: $companyId, employees: $employees)
    }
   }
  }
`;
  Mutations.bulkCaseCreate = gql`
  mutation bulkCaseCreate($cases: [BulkCaseCreateInput!]!) {
      bulkCaseCreate(bulkCaseData: $cases)
  }
`;
  Mutations.setupCompany = gql`
  mutation setupCompany($companyName: String!, $employeeCount: Int!, $country: String!, $firstName: String!, $lastName: String!, $email: String!, $title: String!, $phone: String!)
  {
    setupCompany(
      companyName: $companyName,
      employeeCount: $employeeCount,
      country: $country
      firstName: $firstName
      lastName: $lastName
      email: $email
      title: $title
      phone: $phone
    )
  } `;
  Mutations.resendConnectEmail = gql`
  mutation resendConnectEmail($companyId: String!, $inviteId: String!) {
    resendConnectEmail(companyId: $companyId, inviteId: $inviteId)
  } `;
  Mutations.connectUserToCompany = gql`
  mutation connectUserToCompany($companyId: String!, $userId: String!, $inviteId: String!) {
    connectUserToCompany(companyId: $companyId, userId: $userId, inviteId: $inviteId)
  } `;
  Mutations.syncHris = gql`
    mutation syncHRIS($companyId: String!) {
      queueHrisSync(companyId: $companyId)
  }
  `;
  Mutations.setPublicToken = gql`
    mutation setPublicToken($publicToken: String!, $companyId: String!) {
    hris {
      merge {
        setPublicToken(publicToken: $publicToken, companyId: $companyId)
      }
    }
  }
  `;
  Mutations.loadOpportunity = gql`
    mutation loadOpportunity($opportunityId: String) {
    loadOpportunity(opportunityId: $opportunityId)
  } `;

  Mutations.setUserClaims = gql`
  mutation setUserClaims($uid: String, $admin: Boolean) {
    setUserClaims(uid: $uid, admin: $admin){
      uid,
        email,
        admin
    }
  } `;
  Mutations.setUserDirectDeposit = gql`
  mutation setUserDirectDeposit($accountNumber: String!, $nameOnAccount: String!, $routingNumber: String!) {
    setUserDirectDeposit(
      accountNumber: $accountNumber,
      nameOnAccount: $nameOnAccount,
      routingNumber: $routingNumber
    )
  } 
  `;
  Mutations.queueHrisSync = gql`
  mutation queueHrisSync($companyId: String!) {
    queueHrisSync(companyId: $companyId)
  }
  `;
  Mutations.connectFinch = gql`
    mutation connectFinch($clientCode:String!, $companyId:String!) {
      hris {
        finch {
          connectFinch(clientCode: $clientCode, companyId: $companyId)
        }
      }
    }
  `;
  Mutations.adminCreateUserFromCase = gql`
  mutation adminCreateUserFromCase($companyId: String!, $caseId: String!, $firstName: String!, $lastName: String!, $email: String!, $dateOfBirth: String!, $zipCode: String!) {
    adminCreateUserFromCase(companyId: $companyId, caseId: $caseId, firstName: $firstName, lastName: $lastName, email: $email, dateOfBirth: $dateOfBirth, zipCode: $zipCode) {
      uid,
      email,
      admin
    }
  }
  `;
  try {

    return Mutations;
  } catch (err: any) {
    err.message = "getMutations: " + err.message;
    throw err;
  }
}
const Mutations: _Mutations = getMutations();

export { Mutations };