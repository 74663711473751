/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Button, Card, CardContent, Checkbox, FormControlLabel, Divider, Accordion, AccordionDetails, AccordionSummary, Slider, Rating, TablePagination, CircularProgress, Dialog, Radio, Tooltip, Skeleton, Chip } from "@mui/material";
import { useAuth } from "contexts/User";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFirebase } from "contexts/Firebase";
import { collection, doc, getDoc, query, setDoc, updateDoc, where } from "firebase/firestore";
import { Application, BenefitPlan, InsuranceType, QuotitHealthPlanDetails } from "services/Interfaces";
import { useEffect, useRef, useState } from "react";
import theme from "theme";
import { FormattedNumber } from "react-intl";
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { Queries, useLazyQuery } from "services/apollo";
import moment from "moment";
import SelectPlanModalQuotit from "components/HealthPlan/SelectPlanModalQuotit";
import ViewDetailsModalQuotit from "components/HealthPlan/ViewDetailsModalQuotit";
import UpdateCobraPlanModal from "components/HealthPlan/UpdateCobraPlanModal";
import UpdateInfo from "components/HealthPlan/UpdateInfo";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Banner from "components/common/Banner";
import { useNavigate } from "react-router";
import { applicationConverter } from "services/Firestore/Application";
import { useRemoteConfig } from "contexts/RemoteConfig";

const plans = [
  'HMO',
  'PPO',
  'ΕΡΟ',
  'POS',
  'HDHP',
];

const metalLevel = [
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
  'Catastrophic'
]

const planTypePreferences = [
  { fieldName: "planType.hmo", name: "HMO" },
  { fieldName: "planType.ppo", name: "PPO" },
  { fieldName: "planType.shortterm", name: "Short Term Medical" },
]

const otherPreferences = [
  { fieldName: "lowPremium", name: "Low Premiums" },
  { fieldName: "lowDeductible", name: "Low Deductible" },
  { fieldName: "comprehensiveCoverage", name: "Comprehensive Coverage" },
  { fieldName: "mentalHealth", name: "Mental Health" },
  { fieldName: "dental", name: "Dental" },
  { fieldName: "vision", name: "Vision" },
]

function InsurancePlans() {
  const debug = useRemoteConfig("debug").asBoolean() || false;
  const ideon = useRemoteConfig("ideon").asBoolean() || false;
  const { firestore } = useFirebase();
  const { user, userData } = useAuth();
  const whenEnrollmentFlag = useRemoteConfig("whenEnrollment").asBoolean() || false;
  const navigate = useNavigate()
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [planComparison, setPlanComparison] = useState<BenefitPlan>()
  const [filterInsuranceType, setFilterInsuranceType] = useState("")
  const [openFilterOptions, setOpenFilterOptions] = useState(false)
  const [selectFilterPlanTypes, setSelectFilterPlanTypes] = useState<string[]>([]);
  const [openPlanTypeOptions, setOpenPlanTypeOptions] = useState(true)
  const [selectMetalLevel, setSelectMetalLeval] = useState<string[]>([]);
  const [openMetalLevelOptions, setOpenMetalLevelOptions] = useState(true)
  const [openPrefrenceOptions, setOpenPrefreneceOptions] = useState(true)
  const [selectFilterPreferences, setSelectFilterPreferences] = useState<{ fieldName: string, preferences: number }[]>([])
  const [insurancePlans, setInsurancePlans] = useState<Array<QuotitHealthPlanDetails>>([]);
  const [plansOnPage, setPlansOnPage] = useState<Array<QuotitHealthPlanDetails>>([]);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [missingDetails, setMissingDetails] = useState<Array<string>>([]);
  const [showComparison, setShowComparison] = useState<boolean>(false);
  const [imageError, setImageError] = useState(false);
  const [openUpdateCobraPlan, setOpenUpdateCobraPlan] = useState<boolean>(false)
  const [planDetailsOpen, setPlanDetailsOpen] = useState<boolean>(false);
  const [selectPlanOpen, setSelectPlanOpen] = useState<boolean>(false);
  const [federalSubsidyAmount, setFederalSubsidyAmount] = useState<number>(0);
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // Get user's most recent draft application.
  const [application] = useCollection<Application>(
    user ?
      query(
        collection(firestore, `users/${user?.uid}/applications`),
        where("status", "==", "draft"),
      ).withConverter(applicationConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  // Sort applications by plan.effectiveDate (ascending) and then by createdAt (descending)
  const applications = application?.docs?.map(doc => doc.data());
  applications?.sort((a: Application, b: Application) => {
    if (a.plan.effectiveDate && !b.plan.effectiveDate) {
      return -1; // memberA has createdAt, memberB does not
    }
    if (!a.plan.effectiveDate && b.plan.effectiveDate) {
      return 1; // memberB has createdAt, memberA does not
    }
    const dateA = a.plan.effectiveDate ? moment(a.plan.effectiveDate) : moment(0);
    const dateB = b.plan.effectiveDate ? moment(b.plan.effectiveDate) : moment(0);

    if (dateA.isSame(dateB)) {
      const createdAtA = a.createdAt ? moment(a.createdAt) : moment(0);
      const createdAtB = b.createdAt ? moment(b.createdAt) : moment(0);
      return createdAtB.isBefore(createdAtA) ? -1 : 1; // descending order for createdAt
    }

    return dateA.isBefore(dateB) ? -1 : 1; // ascending order for plan.effectiveDate
  });

  // Get user's most recent draft application.
  const topRef = useRef<HTMLDivElement>(null);
  const [reScorePlans] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.reScorePlans, {
    notifyOnNetworkStatusChange: true,
  });

  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [formerEmployment] = useDocument(user && userData ? doc(firestore, `users/${user?.uid}/formerEmployments/${userData?.data()?.syncEmployeeId}`) : null);
  useEffect(() => {
    console.log("formerEmployment", formerEmployment?.data())
  })
  useEffect(() => {
    if (!answersLoading) {
      // check if all required data is set
      let details = []
      const members = answers?.get("insuranceDetails.household.members");
      const insuranceDetails = answers?.get("insuranceDetails");

      if (members === undefined || Object.keys(members).length === 0) {
        // need to set household
        details.push("members");
      }
      if (insuranceDetails === undefined || insuranceDetails.county === undefined || insuranceDetails.income === undefined || insuranceDetails.type === undefined) {
        details.push("details");
      }
      setMissingDetails(details);
    }
  }, [answers, answersLoading])

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    let amount = answers?.get("insuranceDetails.federalSubsidyAmount");
    if (amount) {
      setFederalSubsidyAmount(amount);
    } else {
      setFederalSubsidyAmount(0);
    }
  }, [answers]);

  useEffect(() => {
    const fetchPlanComparision = async () => {
      const planComparisonDoc = await getDoc(doc(firestore, `users/${user?.uid}/settings/insurance`))
      if (planComparisonDoc?.data()?.cobra?.planComparison !== null) {
        setPlanComparison(planComparisonDoc?.data()?.cobra?.planComparison)
      }
    }
    fetchPlanComparision()
  }, [firestore, user?.uid])

  const userPreferencesDoc = user ? doc(firestore, 'users', user?.uid!, "answers", "preferences") : null;
  const [preferences, preferencesLoading] = useDocument(
    userPreferencesDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  function getSubsidyAmount(originalPremium: number, subsidyAmount: number) {
    if (subsidyAmount < originalPremium) {
      return originalPremium - subsidyAmount;
    }
  }
  useEffect(() => {
    if (userPreferencesDoc && preferences && !preferencesLoading && !preferences.exists()) {
      console.log("setting default preferences")
      setDoc(userPreferencesDoc, {
        lowPremium: 0.5,
      }, { merge: true })
    }
  }, [preferences, userPreferencesDoc, preferencesLoading])
  useEffect(() => {
    const newPlanTypePreferences = planTypePreferences.map((p) => {
      const score = preferences?.get(p.fieldName);
      return score !== undefined ? { fieldName: p.fieldName, preferences: score } : null;
    }).filter(Boolean) as { fieldName: string, preferences: number }[];

    setSelectFilterPreferences((prevPreferences) => [...prevPreferences, ...newPlanTypePreferences]);

    const newOtherPreferences = otherPreferences.map((p) => {
      const score = preferences?.get(p.fieldName);
      return score !== undefined ? { fieldName: p.fieldName, preferences: score } : null;
    }).filter(Boolean) as { fieldName: string, preferences: number }[];

    setSelectFilterPreferences((prevPreferences) => [...prevPreferences, ...newOtherPreferences]);

  }, [preferences]);

  const [insurancePlansDoc, plansLoading, plansError] = useCollection<QuotitHealthPlanDetails>(
    query<QuotitHealthPlanDetails>(
      collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate") || moment().add(1, 'month').startOf('month').format("YYYY-MM-DD")}/plans`).withConverter(QuotitPlanConverter),
      // orderBy("recommendation.internalScore", "desc"),
      // where("contractStatus", "==", "Contracted"),
    )
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  useEffect(() => {
    if (!insurancePlansDoc || hasInitialized) return;

    const uniquePlans = new Set(insurancePlans.map((plan) => JSON.stringify(plan)));

    insurancePlansDoc?.docs.forEach((plan: any) => {
      uniquePlans.add(JSON.stringify(plan.data()));
    });

    setInsurancePlans(Array.from(uniquePlans)
      .map((plan) => JSON.parse(plan))
      .sort((a, b) => b.recommendation.internalScore - a.recommendation.internalScore)
    );
    setHasInitialized(true)
  }, [hasInitialized, insurancePlans, insurancePlansDoc]);

  useEffect(() => {
    if (!filterInsuranceType) {
      let preFilteredPlans = insurancePlansDoc?.docs.filter((plan) => plan.data().insuranceType === InsuranceType.federalExchangePlan);

      if (preFilteredPlans && preFilteredPlans.length === 0) {
        preFilteredPlans = insurancePlansDoc?.docs.filter((plan) => plan.data().insuranceType === InsuranceType.privateMedicalPlan);
        if (preFilteredPlans && preFilteredPlans.length === 0) {
          preFilteredPlans = insurancePlansDoc?.docs.filter((plan) => plan.data().insuranceType === InsuranceType.shortTermPlan);
          if (preFilteredPlans && preFilteredPlans.length > 0) {
            setFilterInsuranceType(InsuranceType.shortTermPlan);
          }
        } else {
          setFilterInsuranceType(InsuranceType.privateMedicalPlan);
        }
      } else {
        setFilterInsuranceType(InsuranceType.federalExchangePlan);
      }
    }
  }, [insurancePlansDoc?.docs, filterInsuranceType]);

  useEffect(() => {
    if (!openFilterOptions) {
      let filteredInsurancePlans: QuotitHealthPlanDetails[] = insurancePlansDoc?.docs.map((plan) => plan.data()) || [];

      if (filterInsuranceType) {
        filteredInsurancePlans = filteredInsurancePlans.filter((plan) => {
          if (plan.onMarket === true && filterInsuranceType === InsuranceType.federalExchangePlan)
            return true;
          if (plan.offMarket === true && filterInsuranceType === InsuranceType.privateMedicalPlan)
            return true;
          return plan.insuranceType === filterInsuranceType
        });
      }

      if (selectFilterPlanTypes.length > 0) {
        filteredInsurancePlans = filteredInsurancePlans.filter((plan) => selectFilterPlanTypes.includes(plan.planType));
      }

      if (selectMetalLevel.length > 0) {
        filteredInsurancePlans = filteredInsurancePlans.filter((plan) => selectMetalLevel.includes(capitalizeFirstLetter(plan.benefitLevel)));
      }

      setInsurancePlans(filteredInsurancePlans.sort((a, b) => b.recommendation.internalScore - a.recommendation.internalScore));
    }
  }, [filterInsuranceType, selectFilterPlanTypes, selectMetalLevel, insurancePlansDoc, openFilterOptions]);

  const applyFilterForMobile = async () => {
    setIsLoading(true);
    if (openFilterOptions) {
      if (selectFilterPlanTypes.length > 0 || selectMetalLevel.length > 0) {
        const filteredInsurancePlans = insurancePlansDoc?.docs
          .filter((plan) =>
            (selectFilterPlanTypes.length === 0 || selectFilterPlanTypes.includes(plan.data().planType)) &&
            (selectMetalLevel.length === 0 || selectMetalLevel.includes(capitalizeFirstLetter(plan.data().benefitLevel)))
          )
          .map((plan) => plan.data());
        setInsurancePlans(filteredInsurancePlans?.sort((a, b) => b.recommendation.internalScore - a.recommendation.internalScore) || []);
      }
      if (userPreferencesDoc) {
        for (const p of selectFilterPreferences) {
          if (preferences?.get(p.fieldName) !== p.preferences) {
            await updateDoc(userPreferencesDoc, {
              [p.fieldName]: p.preferences
            });
            reScore()
          }
        }
      }
      setOpenFilterOptions(false)
      setOpenPlanTypeOptions(true);
      setOpenMetalLevelOptions(true);
      setOpenPrefreneceOptions(true);
      topRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  const eraseAllFilterForMobile = () => {
    setIsLoading(true)
    setSelectFilterPlanTypes([]);
    setSelectMetalLeval([]);
    setOpenFilterOptions(false);
    setOpenPlanTypeOptions(true);
    setOpenMetalLevelOptions(true);
    setOpenPrefreneceOptions(true);
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  async function reScore() {
    //trigger a rescoring call
    setIsLoading(true);
    await reScorePlans({
      variables: {
        effectiveDate: answers?.get("insuranceDetails.effectivedate")
      },
      fetchPolicy: "network-only"
    })
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setPlansOnPage(insurancePlans.slice(startIndex, endIndex));
  }, [page, rowsPerPage, insurancePlans]);

  return (
    <Box sx={{
      backgroundColor: "gray.light",
      display: "flex",
      justifySelf: "center"
    }}>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        {
          whenEnrollmentFlag && applications?.length ?
            <Box mt={2} sx={{ position: "sticky", borderRadius: "8px", zIndex: 100, top: { md: "150px" }, boxShadow: "2px 5px 14px", backgroundColor: "transparent" }}>
              <Banner
                btnText="Go to application"
                bodyText={
                  <>Your enrollment deadline is <strong>{moment(applications[0]?.plan?.effectiveDate, "MM-DD-YYYY").diff(moment().startOf('day'), 'days')}</strong> days away! Click{" "}
                    <a href={`/account/applications/${applications[0]?.id}`} style={{ color: "inherit" }}>
                      <strong>Here</strong>
                    </a>
                    {" "}to finish your application.
                  </>
                }
                onSave={() => navigate(`/account/applications/${applications[0]?.id}`)}
              />
            </Box> : ""
        }
        <Grid container alignItems="stretch" sx={{
          py: 3
        }}>
          {/* user details */}
          <Grid item xs={12} sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            backgroundColor: "white.main",
            padding: 2,
            borderRadius: "8px",
            borderLeft: theme => `6px solid ${theme.palette.primary.main}`,
          }}>
            <Grid item xs={12} sm={9}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  [theme.breakpoints.up('sm')]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <Typography> Zipcode: {answers && answers.get("insuranceDetails.zipcode") ? `${answers.get("insuranceDetails.zipcode")}` : "--"} </Typography>
                <Typography sx={{ display: { xs: "none", sm: "block" } }}>&nbsp;&bull;&nbsp;</Typography>
                <Typography data-test-id="effective-date-label"> Effective Date: {answers && answers.get("insuranceDetails.effectiveDate") ? `${answers.get("insuranceDetails.effectiveDate")}` : "--"} </Typography>
              </Box>
              <Typography>
                You can update your information anytime by going to your{" "}
                <a href="/account/My-Account" style={{ textDecoration: "underline", color: "inherit" }}>
                  Profile
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginX: 2,
                marginY: 1
              }}>
                <Button color={"primary"} data-testid="update-info-button" onClick={() => setDetailsOpen(true)} variant="contained" sx={{ paddingX: 3, paddingY: 1, width: { xs: "40vw", sm: "10vw" }, borderRadius: 0 }}>Update Info</Button>
              </Box>
            </Grid>
          </Grid >

          {((planComparison && planComparison?.name) || showComparison) && <>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginY: 3
            }}>
              <Typography color={"primary"} variant="h1" sx={{ fontWeight: 600 }}>Current Plan</Typography>
              <Typography color={"primary"} variant="h4">Your current monthly <strong>COBRA</strong> cost: <FormattedNumber value={answers?.get("cobra.estimate") ? answers?.get("cobra.estimate") : 0} style={`currency`} currency="USD" /></Typography>
            </Box>

            {/* cobra plan details */}
            <Grid item xs={12} sm={2.5} sx={{
              backgroundColor: "primary.main",
              borderTopLeftRadius: { xs: '12px' },
              borderTopRightRadius: { xs: '12px', sm: 0 },
              paddingY: 2,
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                height: "100%",
                paddingX: 2,
                borderRight: { xs: "none", sm: "1px solid #fff" },
                borderBottom: { xs: "1px solid #194142", sm: "none" },
              }}>
                <Typography sx={{ color: "white.main" }} variant="subtitle1">Your Current Plan</Typography>
                <Typography sx={{ color: "white.main", fontWeight: 600 }} variant="h4">{planComparison?.name ? planComparison?.name : "--"}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} sx={{
              backgroundColor: { xs: "white.main", sm: "primary.main" },
              paddingY: 2,
              paddingX: { xs: 2, sm: 0 },
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                alignContent: "center",
                justifyContent: "space-between",
                height: "100%",
                paddingX: { xs: 0, sm: 2 },
                borderRight: { xs: "none", sm: "1px solid #fff" },
                borderBottom: { xs: "1px solid #194142", sm: "none" },
                color: { xs: "primary", sm: "white.main" },
              }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Typography variant="subtitle1" >Plan Type</Typography>
                  <Typography sx={{ fontWeight: 600, marginBottom: 1, }} variant="h4">{planComparison?.planNetworkType ? planComparison?.planNetworkType : "--"}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Typography variant="subtitle1" > Insurance Type</Typography>
                  <Typography sx={{ fontWeight: 600, }} variant="h4">COBRA</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} sx={{
              backgroundColor: { xs: "white.main", sm: "primary.main" },
              paddingY: 2,
              paddingX: { xs: 2, sm: 0 },
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                height: "100%",
                paddingX: { xs: 0, sm: 2 },
                borderRight: { xs: "none", sm: "1px solid #fff" },
                borderBottom: { xs: "1px solid #194142", sm: "none" },
                color: { xs: "primary", sm: "white.main" }
              }}>
                <Typography variant="subtitle1">Monthly premium</Typography>
                <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4"><FormattedNumber value={answers?.get("cobra.estimate") ? answers?.get("cobra.estimate") : 0} style={`currency`} currency="USD" /></Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} sx={{
              backgroundColor: { xs: "white.main", sm: "primary.main" },
              paddingY: 2,
              paddingX: { xs: 2, sm: 0 }
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                height: "100%",
                paddingX: { xs: 0, sm: 2 },
                borderRight: { xs: "none", sm: "1px solid #fff" },
                borderBottom: { xs: "1px solid #194142", sm: "none" },
                color: { xs: "primary", sm: "white.main" }
              }}>
                <Typography variant="subtitle1">Insurer</Typography>
                <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4">{planComparison?.carrier ? planComparison?.carrier : "--"}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2.5} sx={{
              backgroundColor: { xs: "white.main", sm: "primary.main" },
              borderTopRightRadius: { xs: 0, sm: "12px" },
              padding: 2,
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                alignContent: "center",
                justifyContent: "space-between",
                height: "100%",
                borderBottom: { xs: "1px solid #194142", sm: "none" },
                color: { xs: "primary", sm: "white.main" }
              }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Typography variant="subtitle1">Deductible</Typography>
                  {planComparison && answers && (answers?.get("insuranceDetails.type") === "family") &&
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4"><FormattedNumber value={planComparison?.familyDeductible ? planComparison?.familyDeductible : 0} style={`currency`} currency="USD" /></Typography>}
                  {planComparison && answers && (answers?.get("insuranceDetails.type") === "individual") &&
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4"><FormattedNumber value={planComparison?.individualDeductible ? planComparison?.individualDeductible : 0} style={`currency`} currency="USD" /></Typography>}
                  {!planComparison || !answers ? (
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4">
                      <FormattedNumber value={0} style={`currency`} currency="USD" />
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Typography variant="subtitle1"> Max Out of Pocket</Typography>
                  {planComparison && answers && (answers?.get("insuranceDetails.type") === "family") &&
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4"><FormattedNumber value={planComparison?.familyOutOfPocketMaximum ? planComparison?.familyOutOfPocketMaximum : 0} style={`currency`} currency="USD" /></Typography>}
                  {planComparison && answers && (answers?.get("insuranceDetails.type") === "individual") &&
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4"><FormattedNumber value={planComparison?.individualOutOfPocketMaximum ? planComparison?.individualOutOfPocketMaximum : 0} style={`currency`} currency="USD" /></Typography>}
                  {!planComparison || !answers ? (
                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h4">
                      <FormattedNumber value={0} style={`currency`} currency="USD" />
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "white.main", marginBottom: 4, borderBottomRightRadius: "12px", borderBottomLeftRadius: "12px" }}>
              <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 2,
              }}>
                <Button color={"primary"} variant="outlined" sx={{ paddingX: 3, paddingY: 1, marginX: 2, width: { xs: "40vw", sm: "10vw" }, borderRadius: 0 }} onClick={() => { setOpenUpdateCobraPlan(true) }}>Update plan</Button>
                {/* <Button color={"primary"} variant="contained" sx={{ paddingX: 3, paddingY: 1, marginX: 2, width: { xs: "40vw", sm: "12vw" }, borderRadius: 0 }}>See Plan Details</Button> */}
              </Box>
            </Grid>
          </>}

          {/* insurance options details */}
          <Box
            sx={{
              ...(openFilterOptions ? { display: { xs: "none", sm: "flex" } } : { display: "flex" }),
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              pt: !planComparison ? 2 : 0
            }}
            ref={topRef}
          >
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2
            }}>
              <Typography color={"primary"} variant="h1" sx={{ fontWeight: 600 }}>Insurance Options</Typography>
              <Button variant="outlined" sx={{
                display: !planComparison && formerEmployment?.data()?.cobraPlans?.length > 0 && !showComparison ? "block" : "none",
              }}
                onClick={() => { setShowComparison(!showComparison) }}
              >unhide cobra comparison</Button>
            </Box>
            <FilterListIcon color="primary" sx={{ display: { xs: "block", sm: "none" } }} onClick={() => { setOpenFilterOptions(true); setOpenPlanTypeOptions(false); setOpenMetalLevelOptions(false); setOpenPrefreneceOptions(false); }} />
          </Box>

          {/* filter options */}
          <Grid item xs={12} sm={3} sx={{
            marginY: 1
          }}>

            {/* insurance type filter options */}
            {/* <Box
              sx={{
                ...(openFilterOptions ? { display: { xs: "none", sm: "flex" } } : { display: "flex" }),
                flexDirection: { xs: "row", sm: "column" },
                alignContent: "center",
                overflowX: { xs: "auto", sm: "hidden" }, // Enable horizontal scrollbar
                flexWrap: "nowrap", // Prevent wrapping of cards
                "& > *": { // Apply styles to direct children
                  flex: "0 0 auto", // Prevent cards from growing
                },
              }}
            >
              <Card sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: 1,
                cursor: "pointer",
                width: { xs: '80vw', sm: "95%" },
                ...(filterInsuranceType === InsuranceType.federalExchangePlan ? { backgroundColor: "primary.main", color: "white.main" } : null)
              }}
                onClick={() => {
                  if (filterInsuranceType !== InsuranceType.federalExchangePlan) {
                    setFilterInsuranceType(InsuranceType.federalExchangePlan)
                  } else {
                    setFilterInsuranceType("")
                  }
                }}>
                <CardContent>
                  <Typography sx={{ fontWeight: 500, marginBottom: 1 }} variant="h4">Federal Exchange Plans</Typography>
                  <Typography variant="subtitle1" sx={{ whiteSpace: "normal" }}>Plans available through the Health Insurance Marketplace, established by the Affordable Care Act (ACA).</Typography>
                </CardContent>
              </Card>
              <Card sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
                margin: 1,
                width: { xs: '80vw', sm: "95%" },
                ...(filterInsuranceType === InsuranceType.privateMedicalPlan ? { backgroundColor: "primary.main", color: "white.main" } : null)
              }}
                onClick={() => {
                  if (filterInsuranceType !== InsuranceType.privateMedicalPlan) {
                    setFilterInsuranceType(InsuranceType.privateMedicalPlan)
                  } else {
                    setFilterInsuranceType("")
                  }
                }}>
                <CardContent>
                  <Typography sx={{ fontWeight: 500, marginBottom: 1 }} variant="h4">Private Medical</Typography>
                  <Typography variant="subtitle1" sx={{ whiteSpace: "normal" }}>Health coverage that&apos;s not employer-based, available through insurers or the ACA marketplace.</Typography>
                </CardContent>
              </Card>
              <Card sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
                margin: 1,
                width: { xs: '80vw', sm: "95%" },
                ...(filterInsuranceType === InsuranceType.shortTermPlan ? { backgroundColor: "primary.main", color: "white.main" } : null)
              }}
                onClick={() => {
                  if (filterInsuranceType !== InsuranceType.shortTermPlan) {
                    setFilterInsuranceType(InsuranceType.shortTermPlan)
                  } else {
                    setFilterInsuranceType("")
                  }
                }}>
                <CardContent>
                  <Typography sx={{ fontWeight: 500, marginBottom: 1 }} variant="h4">Short Term Medical</Typography>
                  <Typography variant="subtitle1" sx={{ whiteSpace: "normal" }}>Temporary health coverage for those in transition or without standard insurance.</Typography>
                </CardContent>
              </Card>
            </Box> */}
            <Box>
              <Box sx={{
                ...(openFilterOptions ? { display: { xs: "flex", sm: "none" } } : { display: "none" }),
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}>
                <Typography color="primary.main" variant="h3" sx={{ marginY: 2, fontWeight: 500 }}>Insurance Type</Typography>
                <CloseIcon color="primary" onClick={() => { setOpenFilterOptions(false); setOpenPlanTypeOptions(true); setOpenMetalLevelOptions(true); setOpenPrefreneceOptions(true) }} />
              </Box>
            </Box>

            {/* plan type filter options */}
            <Card sx={{
              ...(openFilterOptions ? { display: "flex" } : { display: { xs: "none", sm: "flex" } }),
              flexDirection: "column",
              justifyContent: "space-between",
              margin: 1
            }}>
              <Card >
                <CardContent sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                  onClick={() => { setOpenPlanTypeOptions(!openPlanTypeOptions) }}>
                  <Typography sx={{ fontWeight: 500, margin: 1 }} variant="h4">{openFilterOptions ? "Insurance Type" : "Insurance Type"}</Typography>
                  {openPlanTypeOptions ?
                    <KeyboardArrowUpOutlinedIcon color="primary" /> :
                    <KeyboardArrowDownOutlinedIcon color="primary" />}

                </CardContent>
              </Card>
              {openPlanTypeOptions &&
                <CardContent sx={{
                  padding: 2,
                }}>
                  <Typography color="gray.main" variant="subtitle1">Select insurance type</Typography>
                  <FormControlLabel
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    control={
                      <Radio
                        name="plans"
                        value={InsuranceType.federalExchangePlan}
                        color="primary"
                        checked={filterInsuranceType === InsuranceType.federalExchangePlan}
                        onChange={() => {
                          setIsLoading(true);
                          setFilterInsuranceType(filterInsuranceType === InsuranceType.federalExchangePlan ? "" : InsuranceType.federalExchangePlan);
                          setTimeout(() => {
                            setIsLoading(false);
                          }, 500);
                        }}
                      />
                    }
                    label={
                      <Tooltip title="Plans available through the Health Insurance Marketplace, established by the Affordable Care Act (ACA)." placement="top">
                        <Typography>Federal Exchange Plans</Typography>
                      </Tooltip>
                    }
                  />

                  <FormControlLabel
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    control={
                      <Radio
                        name="plans"
                        value={InsuranceType.privateMedicalPlan}
                        color="primary"
                        checked={filterInsuranceType === InsuranceType.privateMedicalPlan}
                        onChange={() => {
                          setIsLoading(true);
                          setFilterInsuranceType(filterInsuranceType === InsuranceType.privateMedicalPlan ? "" : InsuranceType.privateMedicalPlan);
                          setTimeout(() => {
                            setIsLoading(false);
                          }, 500);
                        }}
                      />
                    }
                    label={
                      <Tooltip title="Health coverage that's not employer-based, available through insurers or the ACA marketplace." placement="top">
                        <Typography>Private Medical</Typography>
                      </Tooltip>
                    }
                  />

                  <FormControlLabel
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    control={
                      <Radio
                        name="plans"
                        value={InsuranceType.shortTermPlan}
                        color="primary"
                        checked={filterInsuranceType === InsuranceType.shortTermPlan}
                        onChange={() => {
                          setIsLoading(true);
                          setFilterInsuranceType(filterInsuranceType === InsuranceType.shortTermPlan ? "" : InsuranceType.shortTermPlan);
                          setTimeout(() => {
                            setIsLoading(false);
                          }, 500);
                        }}
                      />
                    }
                    label={
                      <Tooltip title="Temporary health coverage for those in transition or without standard insurance." placement="top">
                        <Typography>Short Term Medical</Typography>
                      </Tooltip>
                    }
                  />
                </CardContent>}
            </Card>


            <Box>
              <Box sx={{
                ...(openFilterOptions ? { display: { xs: "flex", sm: "none" } } : { display: "none" }),
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}>
                <Typography color="primary.main" variant="h3" sx={{ marginY: 2, fontWeight: 500 }}>Filter your options:</Typography>
                <CloseIcon color="primary" onClick={() => { setOpenFilterOptions(false); setOpenPlanTypeOptions(true); setOpenMetalLevelOptions(true); setOpenPrefreneceOptions(true) }} />
              </Box>
            </Box>

            {/* plan type filter options */}
            <Card sx={{
              ...(openFilterOptions ? { display: "flex" } : { display: { xs: "none", sm: "flex" } }),
              flexDirection: "column",
              justifyContent: "space-between",
              margin: 1
            }}>
              <Card >
                <CardContent sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                  onClick={() => { setOpenPlanTypeOptions(!openPlanTypeOptions) }}>
                  <Typography sx={{ fontWeight: 500, margin: 1 }} variant="h4">{openFilterOptions ? "Plans" : "Filter Plans"}</Typography>
                  {openPlanTypeOptions ?
                    <KeyboardArrowUpOutlinedIcon color="primary" /> :
                    <KeyboardArrowDownOutlinedIcon color="primary" />}

                </CardContent>
              </Card>
              {openPlanTypeOptions &&
                <CardContent sx={{
                  padding: 2,
                }}>
                  <Typography color="gray.main" variant="subtitle1">Select your plan</Typography>
                  {plans.map((plan, index) => (
                    <FormControlLabel
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={index}
                      control={
                        <Checkbox
                          name="plans"
                          value={plan}
                          color="primary"
                          checked={selectFilterPlanTypes.includes(plan)}
                          onChange={(event) => {
                            setIsLoading(true);
                            const plan = event.target.value
                            const isChecked = event.target.checked
                            if (isChecked) {
                              setSelectFilterPlanTypes([...selectFilterPlanTypes, plan]);
                            } else {
                              setSelectFilterPlanTypes(selectFilterPlanTypes.filter((p) => p !== plan));
                            }
                            setTimeout(() => {
                              setIsLoading(false);
                            }, 500);
                          }}
                        />
                      }
                      label={plan}
                    />
                  ))}
                </CardContent>}
            </Card>

            {/* Metal level filter options */}
            <Card sx={{
              ...(openFilterOptions ? { display: "flex" } : { display: { xs: "none", sm: "flex" } }),
              flexDirection: "column",
              justifyContent: "space-between",
              marginX: 1,
              marginY: 2
            }}>
              <Card >
                <CardContent sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                  onClick={() => { setOpenMetalLevelOptions(!openMetalLevelOptions) }}>
                  <Typography sx={{ fontWeight: 500, margin: 1 }} variant="h4">{openFilterOptions ? "Metal Level" : "Filter Metal Level"}</Typography>
                  {openMetalLevelOptions ?
                    <KeyboardArrowUpOutlinedIcon color="primary" /> :
                    <KeyboardArrowDownOutlinedIcon color="primary" />}

                </CardContent>
              </Card>
              {openMetalLevelOptions &&
                <CardContent sx={{
                  padding: 2,
                }}>
                  <Typography color="gray.main" variant="subtitle1">Select Metal Level</Typography>
                  {metalLevel.map((metal, index) => (
                    <FormControlLabel
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={index}
                      checked={selectMetalLevel.includes(metal)}
                      control={
                        <Checkbox
                          name="metal level"
                          value={metal}
                          color="primary"
                          onChange={(event) => {
                            setIsLoading(true);
                            const metal = event.target.value
                            const isChecked = event.target.checked
                            if (isChecked) {
                              setSelectMetalLeval([...selectMetalLevel, metal]);
                            } else {
                              setSelectMetalLeval(selectMetalLevel.filter((m) => m !== metal));
                            }
                            setTimeout(() => {
                              setIsLoading(false);
                            }, 500);
                          }}
                        />
                      }
                      label={metal}
                    />
                  ))}
                </CardContent>}
            </Card>

            {/* prefrences filter options */}
            <Card sx={{
              ...(openFilterOptions ? { display: "flex" } : { display: { xs: "none", sm: "flex" } }),
              flexDirection: "column",
              justifyContent: "space-between",
              marginX: 1,
              marginY: 2
            }}>
              <Card onClick={() => { setOpenPrefreneceOptions(!openPrefrenceOptions) }} >
                <CardContent sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                  <Typography sx={{ fontWeight: 500, margin: 1 }} variant="h4">Health Plan Preferences</Typography>
                  {openPrefrenceOptions ?
                    <KeyboardArrowUpOutlinedIcon color="primary" /> :
                    <KeyboardArrowDownOutlinedIcon color="primary" />}

                </CardContent>
              </Card>
              {openPrefrenceOptions &&
                <CardContent sx={{
                  padding: 2,
                }}>
                  <Typography color="gray.main" variant="subtitle1" sx={{ marginBottom: 1 }}>What Type of Plans do you Prefer?</Typography>
                  <Divider sx={{ backgroundColor: "gray.light" }} />
                  {planTypePreferences.map((pref, index) => (
                    <Accordion
                      key={index}
                      sx={{
                        border: "none",
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowDownOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          borderBottom: "1px solid #e0e0e0",
                          padding: 0,
                          "&.Mui-expanded": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                          {pref.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <Slider
                          aria-label={`${pref.name}`}
                          defaultValue={0.6}
                          value={selectFilterPreferences.find((p) => p.fieldName === pref.fieldName)?.preferences || 0.6}
                          step={0.2}
                          marks
                          min={0}
                          max={1}
                          onChange={async (event, value) => {
                            const updatedPreferences = selectFilterPreferences.map((p) => {
                              if (p.fieldName === pref.fieldName) {
                                return { ...p, preferences: Array.isArray(value) ? value[0] : value };
                              }
                              return p;
                            });

                            setSelectFilterPreferences(updatedPreferences);
                          }}
                          onChangeCommitted={async (event, value) => {
                            if (!openFilterOptions && userPreferencesDoc && (preferences?.get(pref.fieldName) !== value)) {
                              await updateDoc(userPreferencesDoc, {
                                [pref.fieldName]: value
                              });
                              reScore();
                            }
                          }}
                        />
                        <Box sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between"
                        }}>
                          <Typography color={"primary"} align="left">Strongly against</Typography>
                          <Typography color={"primary"} align="center">Neutral</Typography>
                          <Typography color={"primary"} align="right">Strongly prefer</Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  <Typography color="gray.main" variant="subtitle1" sx={{ marginY: 1 }}>How important are these to you?</Typography>
                  {otherPreferences.map((pref, index) => (
                    <Accordion
                      key={index}
                      sx={{
                        border: "none",
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowDownOutlinedIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          borderBottom: "1px solid #e0e0e0",
                          padding: 0,
                          "&.Mui-expanded": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                          {pref.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Slider
                          aria-label={`${pref.name}`}
                          defaultValue={0.6}
                          value={selectFilterPreferences.find((p) => p.fieldName === pref.fieldName)?.preferences || 0.6}
                          step={0.2}
                          marks
                          min={0}
                          max={1}
                          onChange={async (event, value) => {
                            const updatedPreferences = selectFilterPreferences.map((p) => {
                              if (p.fieldName === pref.fieldName) {
                                return { ...p, preferences: Array.isArray(value) ? value[0] : value };
                              }
                              return p;
                            });

                            setSelectFilterPreferences(updatedPreferences);
                          }}
                          onChangeCommitted={async (event, value) => {
                            if (!openFilterOptions && userPreferencesDoc && (preferences?.get(pref.fieldName) !== value)) {
                              await updateDoc(userPreferencesDoc, {
                                [pref.fieldName]: value
                              });
                              reScore();
                            }
                          }}
                        />
                        <Box sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between"
                        }}>
                          <Typography color={"primary"} align="left">Very unimportant</Typography>
                          <Typography color={"primary"} align="center">Neutral</Typography>
                          <Typography color={"primary"} align="right">Very important</Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <Button color={"primary"} variant="contained" sx={{ borderRadius: 0, mt: 2 }} onClick={async () => {
                    // Trigger recalculation of plan recommendations
                    await reScore();
                  }}  >Refresh Recommendations</Button>

                </CardContent>
              }
            </Card>

            <Box sx={{
              ...(openFilterOptions ? { display: { xs: "flex", sm: "none" } } : { display: "none" }),
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginY: 2
            }}>
              <Button color={"primary"} variant="outlined" sx={{ paddingX: 3, paddingY: 1, marginX: 2, width: { xs: "40vw", sm: "10vw" }, borderRadius: 0 }} onClick={eraseAllFilterForMobile}>Erase all</Button>
              <Button color={"primary"} variant="contained" sx={{ paddingX: 3, paddingY: 1, marginX: 2, width: { xs: "40vw", sm: "10vw" }, borderRadius: 0 }} onClick={applyFilterForMobile} >Apply</Button>
            </Box>
          </Grid>

          {/* filtered plans */}
          <Grid item xs={12} sm={9} sx={{
            marginY: 2,
            paddingX: 1
          }}>
            {missingDetails.length > 0 &&
              <Box sx={{
                backgroundColor: "white.main",
                width: { xs: "100vw", sm: "50vw" },
                padding: "20px",
                mx: "auto",
                my: 10
              }}>
                <Box sx={{
                  border: "solid",
                  borderColor: "redOrange.main",
                  borderRadius: "10px",
                  p: 4
                }}>
                  <Typography>It looks like you are missing some key information to get health insurance plans.</Typography><br />
                  <Box sx={{
                    display: "flex",
                    gap: 2
                  }}>
                    <Button color={"primary"} onClick={() => setDetailsOpen(true)} variant="contained" sx={{ paddingX: 3, paddingY: 1, width: { xs: "40vw", sm: "10vw" }, borderRadius: 0 }}>Update Info</Button>
                  </Box>
                </Box>
              </Box>
            }
            {(plansLoading || plansError || insurancePlansDoc?.docs.length === 0) && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress
                    sx={{ padding: '10px', color: 'secondary.main', m: 2 }}
                    size={64}
                    thickness={4}
                  />
                </Box>
              </>
            )}

            {plansOnPage.map((plan, index) => {
              let insuranceType;
              if (plan.insuranceType === InsuranceType.federalExchangePlan) {
                insuranceType = "On Exchange"
              } else if (plan.insuranceType === InsuranceType.privateMedicalPlan) {
                insuranceType = "Off Exchange"
              } else if (plan.insuranceType === InsuranceType.shortTermPlan) {
                insuranceType = "Short Term"
              }
              const diff = answers?.get("cobra.estimate") - plan.rate;
              let saveAmount = diff

              let subsidyAmount = federalSubsidyAmount;

              if (ideon) {
                const planRate = plan.rate;
                const planRateSubsidized = plan.rateSubsidized || planRate;
                subsidyAmount = planRate - planRateSubsidized;
              }

              if (subsidyAmount) {
                if (plan.premiumSubsidized) {
                  saveAmount = answers?.get("cobra.estimate") - (plan.premiumSubsidized)
                } else {
                  saveAmount = answers?.get("cobra.estimate") - (plan.rate - subsidyAmount)
                }
              }
              let deductible;
              let maxOutOfPocket;

              const deductibleBenefit = plan.benefits.find((ben: any) =>
                ["ACA1MedicalDeductible", "ACA1MedicalDrugDeductible", "AnnualDeductible"].includes(ben.enum)
              );
              if (deductibleBenefit) {
                deductible = deductibleBenefit.inNetwork;
              }

              const maxOutOfPocketBenefit = plan.benefits.find((ben: any) =>
                ["ACA1EHBMedicalOOPMax", "ACA1EHBDrugOOPMax", "MaximumAnnualCopay"].includes(ben.enum)
              );
              if (maxOutOfPocketBenefit) {
                maxOutOfPocket = maxOutOfPocketBenefit.inNetwork;
              }

              let rating;
              if (plan.rating && plan.rating.overall !== "Not Rated") {
                rating = parseInt(plan.rating.overall);
              }

              return (
                <Grid container key={index}>
                  <Grid item xs={12} sm={4} md={2.5} sx={{
                    backgroundColor: { xs: "#F8F8F8", sm: "white.main" },
                    borderTopLeftRadius: { xs: '12px' },
                    borderTopRightRadius: { xs: '12px', sm: 0 },
                    padding: 2,
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: { xs: "row", sm: "column" },
                      alignContent: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      gap: isLoading ? 4 : 0
                    }}>
                      {!isLoading ?
                        <>
                          <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                            <Typography sx={{ color: "primary.main" }} variant="subtitle1">Name</Typography>
                            <Typography sx={{ color: "primary.main", fontWeight: 600, overflowWrap: 'anywhere' }} variant="h5">{plan.name ? plan.name : "--"}</Typography>
                          </Box>
                          <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingY: 1
                          }}>
                            <VerifiedUserIcon color="primary" />
                            <Typography sx={{ color: "primary.main", fontWeight: 600, }} variant="h5">{plan.benefitLevel ? capitalizeFirstLetter(plan.benefitLevel) : ""}</Typography>
                          </Box></>
                        :
                        <>
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' },
                              marginRight: 2, width: { xs: 170, sm: 'unset' }
                            }} variant="rectangular" height={50} />
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' },
                            }} variant="rectangular" width='80%' height={25} />
                        </>
                      }
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} md={1.6} sx={{
                    backgroundColor: "white.main",
                    paddingY: { xs: 0, sm: 2 },
                    paddingX: { xs: 2, sm: 0 }
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: { xs: "row", sm: "column" },
                      alignContent: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      paddingLeft: { xs: 0, sm: isLoading ? 0 : 2 },
                      paddingRight: { xs: 0, sm: 2 },
                      marginY: { xs: 1, sm: 0 },
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      borderBottom: { xs: "1px solid #DFDFDF", sm: "none" },
                      color: "primary.main",
                      gap: isLoading ? 4 : 0
                    }}>
                      {!isLoading ?
                        <>
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Typography variant="subtitle1" >Plan Type</Typography>
                            <Typography sx={{ fontWeight: 600, marginBottom: 1, }} variant="h5">{plan.planType ? plan.planType : "--"}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Typography variant="subtitle1" > Insurance Type</Typography>
                            <Typography sx={{ fontWeight: 600, }} variant="h5">{insuranceType ? insuranceType : ""}</Typography>
                          </Box>
                        </>
                        :
                        <>
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' }, width: { xs: 170, sm: 'unset' }
                            }} variant="rectangular" height={50} />
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' },
                            }} variant="rectangular" height={25} width='80%' />
                        </>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2.6} sx={{
                    backgroundColor: "white.main",
                    paddingY: { xs: 0, sm: 2 },
                    paddingX: { xs: 2, sm: 0 },
                    borderTopRightRadius: { xs: 0, sm: "12px", md: 0 },
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      height: "100%",
                      paddingX: { xs: 0, sm: 2 },
                      marginY: { xs: 1, sm: 0 },
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      borderBottom: { xs: "1px solid #DFDFDF", sm: "none" },
                      color: "primary.main",
                    }}>
                      {!isLoading ?
                        <>
                          <Typography variant="subtitle1">Monthly premium</Typography>
                          <Box sx={{ display: "flex", flexDirection: { xs: "row", sm: "column" } }}>
                            {subsidyAmount && plan.rate > subsidyAmount && plan?.insuranceType === InsuranceType.federalExchangePlan ?
                              <Tooltip 
                                title={
                                  <>
                                    This premium amount includes an estimated premium tax credit amount of {' '} 
                                    <FormattedNumber 
                                      style={`currency`}
                                      maximumFractionDigits={2} 
                                      currency="USD" 
                                      value={subsidyAmount} 
                                    /> {' '}
                                    per month, based on the income and household information you have provided. The exact amount may vary based on your final application and actual income.
                                  </>
                                } 
                                arrow>
                                <Box display='flex' flexDirection={"column"} sx={{ flexWrap: 'wrap', cursor: "pointer" }}>
                                  <Typography sx={{ fontWeight: 600, marginBottom: 1, textDecoration: 'line-through' }} variant="h5"> <FormattedNumber value={plan.rate ? plan.rate : 0} style={`currency`} currency="USD" /></Typography>
                                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography sx={{ fontWeight: 600, marginBottom: 1 }} variant="h5"><FormattedNumber value={getSubsidyAmount(plan.rate, subsidyAmount) || 0} style={`currency`} currency="USD" /></Typography>
                                    <InfoOutlinedIcon fontSize="small" style={{ verticalAlign: 'middle', paddingTop: '3px' }} />
                                  </Box>
                                </Box>
                              </Tooltip>
                              : 
                                <Typography sx={{ fontWeight: 600, marginBottom: 1, mr: 1 }} variant="h5"> <FormattedNumber value={plan.rate ? plan.rate : 0} style={`currency`} currency="USD" /></Typography>
                            }
                            {(planComparison && planComparison.name && saveAmount > 0) &&
                              <Chip
                                label={`SAVE $${Math.abs(saveAmount).toFixed(2)} VS COBRA`}
                                sx={{
                                  fontSize: '10px',
                                }}
                              />
                            }
                          </Box>
                        </>
                        :
                        <Skeleton animation="wave"
                          sx={{
                            borderRadius: { xs: '12px' },
                          }} variant="rectangular" height={50} />
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2.2} sx={{
                    backgroundColor: "white.main",
                    paddingY: { xs: 0, sm: 2 },
                    paddingX: { xs: 2, sm: 0 }
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      height: "100%",
                      paddingX: { xs: 0, sm: 2 },
                      marginY: { xs: 1, sm: 0 },
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      borderBottom: { xs: "1px solid #DFDFDF", sm: "none" },
                      color: "primary.main",
                    }}>
                      {!isLoading ?
                        <>
                          <Typography variant="subtitle1">Insurer</Typography>
                          {imageError ? (
                            <Typography sx={{ fontWeight: 600, marginBottom: 1, overflowWrap: 'anywhere' }} variant="h6">
                              {plan.carrier.name ? plan.carrier.name : "--"}
                            </Typography>
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <img
                                  src={plan.carrier.logo}
                                  alt={plan.carrier.name}
                                  onError={handleImageError}
                                  style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                        :
                        <Skeleton animation="wave"
                          sx={{
                            borderRadius: { xs: '12px' },
                          }} variant="rectangular" height={50} />
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={1.7} sx={{
                    backgroundColor: "white.main",
                    paddingY: { xs: 0, sm: 2 },
                    paddingX: { xs: 2, sm: 0 }
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: { xs: "row", sm: "column" },
                      alignContent: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      paddingX: { xs: 0, sm: 2 },
                      marginY: { xs: 1, sm: 0 },
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      borderBottom: { xs: "1px solid #DFDFDF", sm: "none" },
                      color: "primary.main",
                      gap: isLoading ? 4 : 0
                    }}>
                      {!isLoading ? <>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <Typography variant="subtitle1" >Deductible</Typography>
                          <Typography sx={{ fontWeight: 600, marginBottom: 1, }} variant="h5">{deductible ? deductible : 0}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <Typography variant="subtitle1" >Max Out of Pocket</Typography>
                          <Typography sx={{ fontWeight: 600, }} variant="h5">{maxOutOfPocket ? maxOutOfPocket : 0}</Typography>
                        </Box>
                      </>
                        :
                        <>
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' }, width: { xs: 170, sm: 'unset' }
                            }} variant="rectangular" height={50} />
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' },
                            }} variant="rectangular" width='80%' height={25} />
                        </>
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={1.4} sx={{
                    backgroundColor: "white.main",
                    borderTopRightRadius: { xs: 0, md: "12px" },
                    paddingY: { xs: 0, sm: 2 },
                    paddingX: { xs: 2, sm: 0 }
                  }}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: { xs: "row", sm: "column" },
                      alignContent: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      paddingX: { xs: 0, sm: 1 },
                      marginY: { xs: 1, sm: 0 },
                      borderRight: { xs: "none", sm: "1px solid #DFDFDF" },
                      borderBottom: { xs: "1px solid #DFDFDF", sm: "none" },
                      color: "primary.main",
                      gap: isLoading ? 4 : 0
                    }}>
                      {!isLoading ?
                        <>
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Typography variant="subtitle1" >Score</Typography>
                            <Typography sx={{ fontWeight: 600, marginBottom: 1, }} variant="h5">{plan.recommendation.internalScore ? plan.recommendation.internalScore : 0}</Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Typography variant="subtitle1" >Star Rating</Typography>
                            <Rating size="small" value={rating ? rating : 0} readOnly sx={{ fontSize: { xs: "1rem", sm: "1rem", md: "0.8rem", lg: "1.125rem" } }} />
                          </Box>
                        </>
                        :
                        <>
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' }, width: { xs: 170, sm: 'unset' },
                            }} variant="rectangular" height={50} />
                          <Skeleton animation="wave"
                            sx={{
                              borderRadius: { xs: '12px' },
                            }} variant="rectangular" width='80%' height={25} />
                        </>}
                    </Box>

                  </Grid>
                  <Grid item xs={12} sx={{ backgroundColor: "#F8F8F8", marginBottom: 2, borderBottomRightRadius: "12px", borderBottomLeftRadius: "12px", borderTop: "1px solid #DFDFDF" }}>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: 2,
                    }}>
                      {isLoading ?
                        <Skeleton animation="wave"
                          sx={{
                            borderRadius: { xs: '12px' },
                          }} variant="rectangular" width={500} height={30} />
                        : <>
                          {debug &&
                            <Typography variant="caption">source: {plan.source}</Typography>
                          }
                          <Button color={"primary"} variant="outlined" sx={{ paddingX: 1, marginX: 2, width: { xs: "40vw", sm: "12vw" }, borderRadius: 0 }} onClick={() => { setSelectedPlan(plan); setPlanDetailsOpen(true) }}>See Plan Details</Button>
                          <Button color={"primary"} variant="contained" sx={{ paddingX: 1, marginX: 2, width: { xs: "40vw", sm: "12vw" }, borderRadius: 0 }} onClick={() => { setSelectedPlan(plan); setSelectPlanOpen(true) }}>Select This Plan</Button>
                        </>
                      }
                    </Box>
                  </Grid>
                </Grid>
              )
            })}
            {(!plansError && !plansLoading && insurancePlansDoc && insurancePlansDoc?.docs.length > 0 && plansOnPage.length === 0) && (
              <>
                <Grid container>
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h3" >No plans found with the applied filters. Please change your selected filters.</Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {insurancePlans.length > 0 &&
              (
                <>
                  <Grid item xs={12} sx={{ backgroundColor: "white.main" }}>
                    <Box sx={{ display: "flex", flex: "column", justifyContent: "space-between" }}>
                      <Box>
                        <TablePagination
                          component="div"
                          count={insurancePlans.length}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sx={{
                            '& .MuiTablePagination-actions': {
                              display: "none"
                            },
                            '& .MuiTablePagination-displayedRows': {
                              display: "none"
                            },
                            '& .MuiTablePagination-selectLabel': {
                              fontSize: { xs: "0.6rem", ms: "0.875rem" },
                            },
                            '& .MuiTablePagination-select': {
                              marginX: { xs: 0, sm: 1 }
                            },
                            '& .MuiTablePagination-input': {
                              fontSize: { xs: "0.6rem", sm: "0.875rem" },
                            },
                            '& .MuiTablePagination-toolbar': {
                              padding: 0,
                              margin: 0,
                              paddingLeft: { xs: 0, sm: 1 }
                            },
                          }}
                        />
                      </Box>
                      <Box >
                        <TablePagination
                          component="div"
                          count={insurancePlans.length}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sx={{
                            margin: 0,
                            p: 0,
                            gap: { xs: 0, sm: "1rem" },
                            paddingX: { xs: 0, sm: 1 },
                            overflow: "hidden",
                            '& .MuiTablePagination-selectLabel': {
                              display: "none",
                            },
                            '& .MuiSelect-icon': {
                              display: "none"
                            },
                            '& .MuiTablePagination-select': {
                              display: "none",
                            },
                            '& .MuiTablePagination-toolbar': {
                              padding: 0,
                              margin: 0
                            },
                            '& .MuiTablePagination-actions': {
                              margin: 0
                            },
                            '& .MuiTablePagination-displayedRows': {
                              fontSize: { xs: "0.6rem", ms: "0.875rem" },
                            },
                            '& .MuiButtonBase-root': {
                              padding: { xs: 0, sm: 1 },
                              fontSize: { xs: "0.9rem", sm: "1rem" },
                              '& .MuiIconButton-root': {
                                padding: { xs: 0, sm: 1 },
                                fontSize: { xs: "0.9rem", sm: "1rem" },
                              }
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              )
            }
          </Grid>

          {/* insurance plan page specific footer */}
          <Grid item xs={12} sm={7} sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 3,
            backgroundColor: "white.main",
          }}>
            <Typography variant="h1">Understanding COBRA and Your Options</Typography>
            <Typography>COBRA is not actually insurance. It&apos;s a federal law in the United States that requires employers to allow former employees to remain on an employer-sponsored health plan for up to 18 moths. It does not, however, require employers to subsidize those plans. That means you often pay significantly more for COBRA plans than you paid as an employee.</Typography>
            <Typography>The Affordable Care Act of 2012 offers some alternatives to COBRA plans. They are often more affordable, but they may not offer the same benefits as your employer plan. Your should read the plan details closely to ensure a plan is right for you and your family.</Typography>
            <Typography>Beyond regular insurance plans, there are other options like Short Term Medical.</Typography>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ backgroundColor: "white.main", }}><img src="/images/Thinking.svg" alt="Thinking" width="100%" /></Grid>
        </ Grid>
      </Container >
      <Dialog
        open={selectPlanOpen}
        onClose={() => { setSelectPlanOpen(false) }}
        fullWidth
        maxWidth="lg"
        sx={{
        }}
        aria-labelledby="select-plan-modal-title"
      >
        <SelectPlanModalQuotit plan={selectedPlan} close={() => { setSelectPlanOpen(false) }}></SelectPlanModalQuotit>

      </Dialog>
      <Dialog
        open={planDetailsOpen}
        onClose={() => { setPlanDetailsOpen(false) }}
        fullWidth
        maxWidth="lg"
        sx={{
        }}
        aria-labelledby="view-details-modal-title"
      >
        <ViewDetailsModalQuotit plan={selectedPlan} close={() => { setPlanDetailsOpen(false) }}></ViewDetailsModalQuotit>

      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={detailsOpen}
        onClose={() => { setDetailsOpen(false) }}
      >
        <Card sx={{
          p: 4, overflow: 'auto'
        }}>

          <UpdateInfo userId={user?.uid} onClose={() => { setDetailsOpen(false) }} />

        </Card>

      </Dialog>
      {openUpdateCobraPlan && <UpdateCobraPlanModal openUpdateCobraPlan={openUpdateCobraPlan} setOpenUpdateCobraPlan={setOpenUpdateCobraPlan} setCobraPlan={setPlanComparison} />}
    </Box >
  );
}
export default InsurancePlans;